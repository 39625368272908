
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer utilities {
  .font-poppins {
    font-family: 'Poppins'
  }
}
@layer base {
  body {
    @apply font-poppins;

  }
   li { 
    @apply px-4;
    /* @apply cursor-point */
  } 
}
/* .content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;

}
.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(112, 157, 255, 0.8),
    hsla(242, 74%, 61%, 0.8)
  )!important;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
